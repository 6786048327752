const Appconstants = {
    'restApiUrl': 'https://restapi.auth.ekultur.org/',
    'facebookAppID': '199784297261299',
    'googleAppID': '1004281352618-2bdv20fu1nrfkacskoihten3hajtfsh5.apps.googleusercontent.com',
    'identityPoolId': 'eu-west-1:9cccda91-712d-4cd7-bd34-001e9eb21ba3',
    'region': 'eu-west-1',
    'userPoolId': 'eu-west-1_pNu5lsicn',
    'userPoolWebClientId': 'um501bbt0r4fdmt57l2ps0ik5'
};

export default Appconstants;